<template>
  <ElDialog
    :title="$t('components.media_outlet_tier_manager.title')"
    :before-close="beforeCloseModal"
    :visible.sync="isVisible"
  >
    <DashboardFiltersV2 class="m-b-m">
      <template #secondary>
        <DashboardFiltersV2Item>
          <DashboardFiltersCountryPicker />
        </DashboardFiltersV2Item>
      </template>
    </DashboardFiltersV2>

    <form v-loading="isLoading" @submit.prevent="submit">
      <div class="columns">
        <div class="column is-4">
          <Subheading>{{ $t('components.media_outlet_tier_manager.select_media_outlets') }}</Subheading>
        </div>

        <div class="column">
          <FormField
            :validator="$v.form.outlets"
            :attribute="$t('components.media_outlet_tier_manager.media_outlet_name') "
          >
            <ElSelect
              v-model="form.outlets"
              :loading="isFetchingMediaOutlets"
              :remote-method="queryForMediaOutlets"
              class="MediaOutletTierPicker"
              value-key="id"
              filterable
              multiple
              remote
              reserve-keyword
            >
              <ElOption
                v-for="outlet in mediaOutlets"
                :key="outlet.id"
                :label="outlet.name"
                :value="outlet"
                class="mediaOutletItem"
              />
            </ElSelect>
          </FormField>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4">
          {{ $t('components.media_outlet_tier_manager.select_tier') }}
        </div>

        <div class="column">
          <FormField :validator="$v.form.tier" :attribute="$t('general.tier') ">
            <ElRadio v-model="form.tier" label="aplus">
              {{ $t('components.media_outlet_tier_manager.tier_a') }}
            </ElRadio>

            <ElRadio v-model="form.tier" label="bplus">
              {{ $t('components.media_outlet_tier_manager.tier_b') }}
            </ElRadio>
          </FormField>
        </div>
      </div>
    </form>

    <div slot="footer" class="dialog-footer">
      <VButton
        class="is-plain is-dark"
        type="button"
        @click="beforeCloseModal()"
      >
        {{ $t('forms.cancel') }}
      </VButton>

      <VButton
        :loading="isLoading"
        type="button"
        class="is-primary is-wider"
        @click="submit"
      >
        {{ $t('forms.save') }}
      </VButton>
    </div>
  </ElDialog>
</template>

<script>
import ElDialog from 'element-ui/lib/dialog'
import ElRadio from 'element-ui/lib/radio'
import ElSelect from 'element-ui/lib/select'
import ElOption from 'element-ui/lib/option'
import _xor from 'lodash/xorBy'
import _orderBy from 'lodash/orderBy'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import Subheading from '@hypefactors/shared/js/components/core/Subheading.vue'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import FormField from '@hypefactors/shared/js/components/forms/FormField.vue'
import DashboardFiltersCountryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersCountryPicker.vue'
import DashboardFiltersV2 from '@hypefactors/shared/js/components/filters/DashboardFiltersV2.vue'
import DashboardFiltersV2Item from '@hypefactors/shared/js/components/filters/DashboardFiltersV2Item.vue'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin.js'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities.js'

import Form from '@/services/forms/Form.js'

const form = new Form({
  outlets: { value: [], rules: { required } },
  tier: { value: null, rules: { required } }
})

export default {
  components: {
    ElDialog,
    ElRadio,
    ElSelect,
    ElOption,
    FormField,
    VButton,
    DashboardFiltersV2,
    DashboardFiltersV2Item,
    DashboardFiltersCountryPicker,
    Subheading
  },

  mixins: [debounceMixin(['queryForMediaOutlets'])],

  data () {
    return {
      isFetchingMediaOutlets: false,
      mediaOutlets: [],
      form: form,
      isVisible: false,
      isLoading: false,
      cancelToken: null
    }
  },

  validations: {
    form: form.rules()
  },

  computed: {
    ...mapGetters('globalFilters', ['selectedCountries'])
  },

  watch: {
    selectedCountries: generateUniqueWatcher('queryForMediaOutlets', false)
  },

  methods: {
    queryForMediaOutlets (search = '') {
      this.isFetchingMediaOutlets = true

      const payload = {
        params: {
          search,
          limit: 20,
          countries: this.selectedCountries,
          'without-tier': [this.activeBrandId]
        }
      }

      this.mediaOutletRequest(payload)
    },

    mediaOutletRequest (payload) {
      this.cancelToken && this.cancelToken.cancel()

      this.cancelToken = this.$api.cancelToken()

      this.$api.get('media-outlets', {
        ...payload,
        cancelToken: this.cancelToken.token
      })
        .then(response => {
          this.isFetchingMediaOutlets = false

          this.mediaOutlets = _orderBy(response.data.data, outlet => outlet['name'].toLowerCase())
        })
        .catch((error) => {
          if (this.$api.isCancelToken(error)) {
            return
          }

          this.isFetchingMediaOutlets = false

          this.$displayRequestError(error, this.$t('errors.media_outlets_could_not_be_fetched'))
        })
    },

    submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.isLoading = true

      return Promise.all(this.form.outlets.map(this.addOutletToBrand))
        .then(() => {
          this.$notify.success({
            title: this.$t('general.success'),
            message: this.$t('success.media_outlet_added_to_brand')
          })

          this.mediaOutlets = _xor(this.mediaOutlets, this.form.outlets, 'id')

          this.$emit('outlets-added', this.form.tier)

          this.isLoading = false

          this.form.reset()

          this.$v.form.$reset()
        })
        .catch((err) => {
          this.isLoading = false

          this.$displayRequestError(err)
        })
    },
    addOutletToBrand (outlet) {
      return this.$api.post(`/brands/${this.activeBrandId}/tiers`, {
        outlet_id: outlet.id,
        tier: this.form.tier
      })
    },

    beforeCloseModal (done) {
      if (!this.form.hasChanges()) {
        return this.resetAndClose(done)
      }

      return this.$confirm(this.$t('warnings.confirm_media_outlet_manager_cancel'))
        .then(() => {
          this.resetAndClose(done)
        }).catch(() => {})
    },

    openModal ({ tier } = {}) {
      this.queryForMediaOutlets()

      this.isVisible = true

      if (tier) {
        this.form.tier = tier
      }
    },

    resetAndClose (done) {
      this.isVisible = false

      done && done()

      this.mediaOutlets = []

      this.form.reset()

      this.$v.form.$reset()
    }
  }
}
</script>
